import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const ReportGroup = () => import('@/entities/report-group/report-group.vue');
const ReportGroupUpdate = () => import('@/entities/report-group/report-group-update.vue');
const ReportGroupDetails = () => import('@/entities/report-group/report-group-details.vue');

const ReportCategory = () => import('@/entities/report-category/report-category.vue');
const ReportCategoryUpdate = () => import('@/entities/report-category/report-category-update.vue');
const ReportCategoryDetails = () => import('@/entities/report-category/report-category-details.vue');

const Report = () => import('@/entities/report/report.vue');
const ReportUpdate = () => import('@/entities/report/report-update.vue');
const ReportDetails = () => import('@/entities/report/report-details.vue');

const Config = () => import('@/entities/config/config.vue');
const ConfigUpdate = () => import('@/entities/config/config-update.vue');
const ConfigDetails = () => import('@/entities/config/config-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'report-group',
      name: 'ReportGroup',
      component: ReportGroup,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/new',
      name: 'ReportGroupCreate',
      component: ReportGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/:reportGroupId/edit',
      name: 'ReportGroupEdit',
      component: ReportGroupUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-group/:reportGroupId/view',
      name: 'ReportGroupView',
      component: ReportGroupDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category',
      name: 'ReportCategory',
      component: ReportCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/new',
      name: 'ReportCategoryCreate',
      component: ReportCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/:reportCategoryId/edit',
      name: 'ReportCategoryEdit',
      component: ReportCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report-category/:reportCategoryId/view',
      name: 'ReportCategoryView',
      component: ReportCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report',
      name: 'Report',
      component: Report,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/new',
      name: 'ReportCreate',
      component: ReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/:reportId/edit',
      name: 'ReportEdit',
      component: ReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'report/:reportId/view',
      name: 'ReportView',
      component: ReportDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config',
      name: 'Config',
      component: Config,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/new',
      name: 'ConfigCreate',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/edit',
      name: 'ConfigEdit',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/view',
      name: 'ConfigView',
      component: ConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
