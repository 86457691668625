import { Query } from '@gv/ammo-astra/dist/services/query/query';
import Qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';

const axiosConfig: AxiosRequestConfig = {
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
};

const baseApiUrl = 'services/farman/api';

export default class BusinessAnalyticsService {
  private readonly queryService;

  constructor(queryService) {
    this.queryService = queryService;
  }

  public query(slug: Query, params: { [k: string]: any }): Promise<any> {
    return this.queryService().query(slug, params);
  }

  public retrieve(url: string, params: { [k: string]: any }) {
    const config: AxiosRequestConfig = axiosConfig;
    config.params = { ...params };

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${url}`, config)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
