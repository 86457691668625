export interface IParameters {
  enabledDateRange?: boolean;
  startDate?: string;
  endDate?: string;
  enabledCompanies?: boolean;
  authorizedCompanies?: Array<{ [k: string]: any }>;
  companies?: Array<string>;
  enabledSites?: boolean;
  authorizedSites?: Array<{ [k: string]: any }>;
  sites?: Array<string>;
  authorizedSalesReps?: Array<{ [k: string]: any }>;
  enabledSalesReps?: boolean;
  salesReps?: Array<string>;
  rowsLimit?: number;
}

export class Parameters implements IParameters {
  constructor(
    public enabledDateRange?: boolean,
    public startDate?: string,
    public endDate?: string,
    public enabledCompanies?: boolean,
    public authorizedCompanies?: Array<{ [k: string]: any }>,
    public companies?: Array<string>,
    public enabledSites?: boolean,
    public authorizedSites?: Array<{ [k: string]: any }>,
    public sites?: Array<string>,
    public enabledSalesReps?: boolean,
    public authorizedSalesReps?: Array<{ [k: string]: any }>,
    public salesReps?: Array<string>,
    public rowsLimit?: number
  ) {}
}
