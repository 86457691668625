import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslationService } from '@gv/ammo-grome';
import EntitiesMenu from '@/entities/entities-menu.vue';

@Component({})
export default class ParameterSelect extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;

  private languages: any = this.$store.getters.languages;

  private currentLanguage = this.$store.getters.currentLanguage;

  private lazyValue: string[] = [];

  @Prop({
    type: Array,
    required: true,
    default: () => {
      return [];
    },
  })
  readonly value;

  @Prop({
    type: Array,
    required: true,
    default: () => {
      return [];
    },
  })
  readonly items!: Array<any>;

  @Prop({ required: true, type: Boolean })
  readonly multiple;

  @Prop({ required: true, type: Boolean })
  readonly dense;

  @Prop({ required: true, type: Boolean })
  readonly dark;

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
    this.lazyValue = this.value;
  }

  @Watch('value', { immediate: true })
  onValueChange(val: any) {
    this.setSelectedItems(val);
  }

  public get allSelected() {
    return this.lazyValue.length === this.items.length;
  }
  public get someSelected() {
    return this.lazyValue.length > 0 && !this.allSelected;
  }
  public get icon() {
    if (this.allSelected) return 'mdi-close-box';
    if (this.someSelected) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public toggle() {
    this.$nextTick(() => {
      if (this.allSelected) {
        this.$set(this, 'lazyValue', []);
      } else {
        this.$set(
          this,
          'lazyValue',
          this.items.map(item => item.value)
        );
      }
      this.input(this.lazyValue);
    });
  }

  public input(payload) {
    this.$set(this, 'lazyValue', [...payload]);
    this.$emit('input', payload);
  }

  public setSelectedItems(val: any) {
    this.lazyValue = val;
  }
}
