/**
 * Formerly NggFlexmonsterParametersBlackbird
 */
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslationService } from '@gv/ammo-grome';
import ParameterSelect from '@/core/spe/parameter/components/parameter-select/parameter-select.vue';
import ParameterDatePickerRange from '@/core/spe/parameter/components/parameter-date-picker-range/parameter-date-picker-range.vue';
import { ParametersEnum } from '@/shared/config/spe/parameters-enum';
import { EventBus, EventBusType } from '@gv/ammo-astra';

@Component({
  components: {
    ParameterDatePickerRange,
    ParameterSelect,
  },
})
export default class ParameterGroup extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;

  @Prop({ type: Object, required: true, default: () => null })
  readonly parameters!: { [k: string]: any };

  private languages: any = this.$store.getters.languages;

  private currentLanguage = this.$store.getters.currentLanguage;

  public parametersComponents: { [k: string]: any } = { ...ParametersEnum };

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
  }

  @Watch('parameters', { immediate: true, deep: true })
  onParametersChange(val: { [k: string]: any }): void {
    this.syncParametersComponents(val);
  }

  beforeDestroy() {
    //
    // Run through parameters keys and set the enable to false
    // -------------------------------------------------------
    // This is  required because the spread operator make a shallow copy of the object.
    // Shallow in this context means that for any given object that is spread, the uppermost level of the new
    // variable is an object containing the same properties and values of the original object, but at a new
    // reference in memory. Any lower level or nested objects, however, will remain pointing to their original
    // references.
    //
    //
    for (const [parameterObjectKey, parameterObjectValue] of Object.entries({ ...ParametersEnum })) {
      parameterObjectValue.enabled = false;
    }
  }

  private syncParametersComponents(val: { [k: string]: any }) {
    if (!val) {
      return;
    }
    // Run through parameters keys
    for (const [parameterObjectKey, parameterObjectValue] of Object.entries(val)) {
      this.parametersComponents[parameterObjectKey].enabled = true;
      // Run through value type {single; range}
      for (const [key, value] of Object.entries(parameterObjectValue)) {
        // @ts-ignore
        this.parametersComponents[parameterObjectKey].enabled = this.parametersComponents[parameterObjectKey].enabled && value.enabled;
        // @ts-ignore
        this.$set(this.parametersComponents[parameterObjectKey]['components'][key], 'dataSource', value.dataSource);
        // @ts-ignore
        this.$set(this.parametersComponents[parameterObjectKey]['components'][key], 'value', value.value);
      }
    }
  }

  /**
   * Builds the payload, notify flexmonster components
   */
  public ok() {
    const payload = this.parameters;
    // Run through parameters keys and update the value key
    for (const [parameterObjectKey, parameterObjectValue] of Object.entries(this.parametersComponents)) {
      if (this.parametersComponents[parameterObjectKey].enabled) {
        // Run through value type {single; range}
        for (const [key, value] of Object.entries(parameterObjectValue.components)) {
          // @ts-ignore
          payload[parameterObjectKey][key].value = value.value;
        }
      }
    }
    this.$emit('refresh-query', payload);
    EventBus.getInstance().dispatch(EventBusType.ToggleDrawer, 'drawerRight');
  }

  public cancel() {
    this.syncParametersComponents(this.parameters);
    EventBus.getInstance().dispatch(EventBusType.ToggleDrawer, 'drawerRight');
  }
}
