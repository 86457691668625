import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
// Import the Pivot component and CSS styles
import Pivot from 'vue-flexmonster';
import 'flexmonster/flexmonster.css';

import { IReport, Report } from '@/shared/model/report.model';
import { AlertService } from '@gv/ammo-grome';
import { AlertType, ERP_HEADERS, FunctionAuthorization, GardianService, IFunctionAuthorization } from '@gv/ammo-astra';
import ReportExtendedService from '@/entities/report/report-extended.service';
import ParameterService from '@/shared/config/spe/parameter.service';
import ParameterGroup from '@/core/spe/parameter/parameter-group.vue';
import { Parameters } from '@/shared/config/spe/parameters';
import qs from 'qs';
import * as UIUtils from '@gv/ammo-astra/dist/shared/util/ui-utils';
import ConfigExtendedService from '@/entities/config/config-extended.service';
import router from '@/router';
import templateParser from './template-parser';

Component.registerHooks(['beforeRouteEnter']);

@Component({
  components: {
    Pivot,
    ParameterGroup,
  },
})
export default class FlexmonsterPivotTable extends Vue {
  @Inject('gardianService')
  private gardianService: () => GardianService;
  @Provide('configExtendedService')
  private configExtendedService = () => new ConfigExtendedService();
  @Provide('reportExtendedService') private reportExtendedService = () => new ReportExtendedService();

  @Inject('alertService') private alertService: () => AlertService;

  @Inject('parameterService')
  private parameterService: () => ParameterService;

  public report: IReport = new Report();

  public currentLanguage = '';

  public isFetching = false;
  public pivotReady = false;
  public formatter = null;
  // Result from parameterService.hydrate
  public parameters: { [k: string]: any } = {};
  public parametersReady = false;
  public flexmonsterKey = '';
  public authorized = true;
  public isParameterDrawerActive = true;
  public queryParameters: { [k: string]: any } = null;
  public skeletonLoader: { [k: string]: any } = {
    boilerplate: true,
    tile: true,
    flexmonster: 'card-heading, date-picker-days, table-thead, table-tbody',
  };

  public flexmonsterPivotTableHeight = window.innerHeight - 168;

  // --- Function authorization ---
  public functionAuthorization: IFunctionAuthorization = new FunctionAuthorization(null, false, false, false, null, [], [], []);
  public isFunctionAuthorizationFetching = false;
  public flexmonsterReport: Flexmonster.Report = {};

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.reportSlug) {
        vm.retrieveReport(to.params.reportSlug);
      }
    });
  }

  customizeToolbar(toolbar: Flexmonster.Toolbar): void {
    toolbar.showShareReportTab = true;
  }

  created(): void {
    this.retrieveKey('flexmonster-key');

    this.currentLanguage = this.$store.getters.currentLanguage;
    this.$store.watch(
      () => this.$store.getters.currentLanguage,
      () => {
        this.currentLanguage = this.$store.getters.currentLanguage;
        this.setLocalization();
      }
    );

    // Listen for window resize events
    window.addEventListener('resize', this.onWindowResize);
  }

  setLocalization() {
    this.flexmonsterReport.localization = `loc/${this.currentLanguage.substring(0, 2)}.json`;
    ((this.$refs.pivot as typeof Pivot).flexmonster as Flexmonster.Pivot).setReport(this.flexmonsterReport);
  }

  public retrieveKey(slug: string): void {
    this.configExtendedService()
      .findBySlug(slug)
      .then(res => {
        this.flexmonsterKey = res.value;
      })
      .catch(error => {
        this.alertService().showHttpError(this, error.response);
      });
  }

  /**
   * Register listeners for the following events:
   *  - dataerror, It is triggered when some error occurred during the loading of data. Please use
   *    olapstructureerror for OLAP data sources.
   *  - dataloaded, It is triggered when the component loaded data. To track possible errors use dataerror.
   *  - loadingdata, It is triggered when data starts loading from local or remote CSV, JSON or after the
   *    report was loaded.
   *  - ready, It is triggered when the component’s initial configuration completed and the component is ready
   *    to receive API calls. Please note that all JS API calls are blocked until the component’s ready event
   *    is dispatched. In other words, when the ready handler is called you know that the component’s creation
   *    completed, and now you can use API calls.
   */

  signOnAllEvents(): void {
    const eventList = [
      'dataerror',
      'dataloaded',
      'loadingdata',
      'ready',
      'loadinglocalization',
      'datachanged',
      'reportchange',
      'beforegriddraw',
      'aftergriddraw',
    ];

    for (const eventName of eventList) {
      //add handler for specified event
      ((this.$refs.pivot as typeof Pivot).flexmonster as Flexmonster.Pivot).on(eventName, () => {
        switch (eventName) {
          case 'dataerror':
          case 'loadingdata':
            this.pivotReady = false;
            break;
          case 'dataloaded':
          case 'ready':
            this.pivotReady = true;
            break;
          case 'loadinglocalization':
          case 'datachanged':
          case 'reportchange':
          case 'beforegriddraw':
          case `aftergriddraw`:
            this.hidePivotTrialMessage();
            break;
          default:
            break;
        }
        this.hidePivotTrialMessage();
      });
    }
  }

  /**
   * Event handler for window resize
   */
  public onWindowResize(event) {
    this.setFlexmonsterPivotTableHeight();
  }

  public setFlexmonsterPivotTableHeight() {
    this.flexmonsterPivotTableHeight = UIUtils.getHeightUntilBottom('pivotGridSelector', 30);
    if (this.$refs.pivot) ((this.$refs.pivot as typeof Pivot).flexmonster as Flexmonster.Pivot).refresh();
  }

  /**
   * Helper function for hide flexmonster trial messages.
   */
  hidePivotTrialMessage() {
    // @ts-ignore
    $('#fm-pivot-view > div.fm-ui-element.fm-ui.fm-noselect').each(function () {
      /* @ts-ignore */
      if ($(this).text().toUpperCase().includes('TRIAL VERSION')) {
        /* @ts-ignore */
        $(this).hide();
      }
    });
  }

  public retrieveReport(reportSlug): void {
    this.reportExtendedService()
      .findBySlug(reportSlug)
      .then(res => {
        res.createdAt = new Date(res.createdAt);
        res.updatedAt = new Date(res.updatedAt);
        this.report = res;
      })
      .then(() => this.retrieveFunctionAuthorization())
      .then(res => {
        this.parameterService().hydrateParameters(this, this.getEndpointParameters(this.report.endpointParameters));
        this.parametersReady = true;
      })
      .then(() => this.updateFlexmonsterReport())
      .catch(error => {
        console.error(error);
      });
  }

  public updateFlexmonsterReport(): Flexmonster.Report {
    if (!this.report?.expression ?? false) {
      return {};
    }
    try {
      this.flexmonsterReport = JSON.parse(this.report.expression);
      this.flexmonsterReport.dataSource = { ...this.compliance(this.flexmonsterReport.dataSource) };

      this.flexmonsterReport.dataSource.requestHeaders = {};
      this.flexmonsterReport.dataSource.requestHeaders[ERP_HEADERS.USER] =
        this.$store.getters.account.customUserAttributes[ERP_HEADERS.USER];
      this.flexmonsterReport.dataSource.requestHeaders[ERP_HEADERS.TENANT_ID] = process.env.ERP_TENANT_ID;
    } catch (e) {
      this.alertService().showAlert(this, AlertType.Error, e.message);
    }
    //then the data needs to be updated in Flexmonster as well
    //this can be done via Flexmonster's updateData() API call:
    ((this.$refs.pivot as typeof Pivot).flexmonster as Flexmonster.Pivot).setReport(this.flexmonsterReport);
  }

  private getEndpointParameters(endpointParameters: string): Parameters {
    let obj: { [k: string]: any } = {};

    const res = this.parameterService().fromFunctionAuthorization(this.functionAuthorization);
    try {
      obj = JSON.parse(endpointParameters);

      res.enabledDateRange = obj.hasOwnProperty('start_date') && obj.hasOwnProperty('end_date');

      if (obj.hasOwnProperty('start_date')) {
        // Deprecated legacy support for php
        if (obj['start_date'] === "php=now()->subMonth()->format('Y-m-d')") {
          const startDate = new Date();
          startDate.setMonth(startDate.getMonth() - 1);
          res.startDate = startDate.toISOString().slice(0, 10);
          // The new way template strings
        } else if (obj['start_date'].startsWith('`') && obj['start_date'].endsWith('`')) {
          res.startDate = templateParser.fill(obj['start_date'], {});
        } else {
          res.startDate = obj['start_date'];
        }
      }
      if (obj.hasOwnProperty('end_date')) {
        // Deprecated legacy support for php
        if (obj['end_date'] === "php=now()->format('Y-m-d')") {
          res.endDate = new Date().toISOString().slice(0, 10);
          // The new way template strings
        } else if (obj['end_date'].startsWith('`') && obj['end_date'].endsWith('`')) {
          res.endDate = templateParser.fill(obj['end_date'], {});
        } else {
          res.endDate = obj['end_date'];
        }
      }
      res.enabledCompanies = obj.hasOwnProperty('companies');
      if (obj.hasOwnProperty('companies') && Array.isArray(obj.companies) && obj.companies.length > 0) {
        res.companies = this.functionAuthorization.authorizedCompanies.filter(it => obj.companies.includes(it.cpy0)).map(it => it.cpy0);
      }
      res.enabledSites = obj.hasOwnProperty('sites');
      if (obj.hasOwnProperty('sites') && Array.isArray(obj.sites) && obj.sites.length > 0) {
        res.sites = this.functionAuthorization.authorizedSites.filter(it => obj.sites.includes(it.fcy0)).map(it => it.fcy0);
      }
      res.enabledSalesReps = obj.hasOwnProperty('sales_reps');
      if (obj.hasOwnProperty('sales_reps') && Array.isArray(obj['sales_reps']) && obj['sales_reps'].length > 0) {
        res.salesReps = this.functionAuthorization.authorizedSalesrep
          .filter(it => obj['sales_reps'].includes(it.repnum0))
          .map(it => it.repnum0);
      }
      res.rowsLimit = obj.hasOwnProperty('rows_limit') ? obj['rows_limit'] : 0;
      return res;
    } catch (e) {
      this.alertService().showAlert(this, AlertType.Error, e.message);
      return res;
    }
  }

  private compliance(oldDataSource: Flexmonster.DataSource) {
    const dataSource: Flexmonster.DataSource = {};
    this.queryParameters = this.parameterService().toQueryParameters(this.parameters);

    dataSource.type = 'csv';

    // TODO : farman
    // org.springframework.web.reactive.function.UnsupportedMediaTypeException: Content type 'text/plain' not supported for bodyType=java.lang.Object
    if (process.env.ERP_BUSINESS_ANALYTICS_URL) {
      const businessAnalyticsBaseApiUrl = 'rafale.roots.grupo-valco.com/services/fargo';
      const mockBusinessAnalyticsBaseApiUrl = process.env.ERP_BUSINESS_ANALYTICS_URL;
      const indexOfOldBusinessAnalyticsBaseApiUrl = (oldDataSource.filename ?? '').indexOf(businessAnalyticsBaseApiUrl);

      dataSource.filename =
        indexOfOldBusinessAnalyticsBaseApiUrl !== -1
          ? `${window.location.protocol}//`.concat(
              mockBusinessAnalyticsBaseApiUrl,
              oldDataSource.filename.substring(indexOfOldBusinessAnalyticsBaseApiUrl + businessAnalyticsBaseApiUrl.length),
              `?${qs.stringify(this.queryParameters, { arrayFormat: 'repeat' })}`
            )
          : oldDataSource.filename;
    } else {
      const url = new URL(oldDataSource.filename);
      dataSource.filename = `${window.location.protocol}//`.concat(
        url.host,
        url.pathname,
        `?${qs.stringify(this.queryParameters, { arrayFormat: 'repeat' })}`
      );
    }
    return dataSource;
  }

  public retrieveFunctionAuthorization(): Promise<boolean> {
    return new Promise(resolve => {
      this.isFunctionAuthorizationFetching = true;

      this.gardianService()
        .functionAuthorization({ function: this.report.authorizationFunction })
        .then(param => {
          this.functionAuthorization.accessTypeObject = param.accessTypeObject;
          this.functionAuthorization.authorizationSite = param.authorizationSite;
          this.functionAuthorization.authorizedAccessTypeObject = param.authorizedAccessTypeObject;
          this.functionAuthorization.defaultSite = param.defaultSite;
          this.functionAuthorization.authorizedCompanies.push(...param.authorizedCompanies);
          this.functionAuthorization.authorizedSites.push(...param.authorizedSites);
          this.functionAuthorization.authorizedSalesrep.push(...param.authorizedSalesrep);
          this.isFunctionAuthorizationFetching = false;
          resolve(true);
        })
        .catch(error => {
          router.go(-1);
          this.isFunctionAuthorizationFetching = false;
          const errMessage = error?.response?.data?.message ?? '';
          this.alertService().showAlert(this, AlertType.Error, `${error.message}${errMessage !== '' ? '<br>' + errMessage : ''}`, null, -1);
          resolve(false);
        });
    });
  }

  refreshQuery(payload) {
    // Update parent object to reflect parameters selections
    this.parameters = payload;
    this.queryParameters = this.parameterService().toQueryParameters(this.parameters);
    this.updateFlexmonsterReport();
  }
}
