import { Component, Inject, Vue } from 'vue-property-decorator';
import SalesDeliveriesCarouselSheet from '@/core/spe/dashboard/sheets/sales-deliveries-carousel-sheet.vue';
import SalesInvoicesCarouselSheet from '@/core/spe/dashboard/sheets/sales-invoices-carousel-sheet.vue';
import SalesOrdersCarouselSheet from '@/core/spe/dashboard/sheets/sales-orders-carousel-sheet.vue';
import SalesQuotesCarouselSheet from '@/core/spe/dashboard/sheets/sales-quotes-carousel-sheet.vue';
import { AccountService } from '@gv/ammo-grome';

@Component({
  components: {
    SalesDeliveriesCarouselSheet,
    SalesInvoicesCarouselSheet,
    SalesOrdersCarouselSheet,
    SalesQuotesCarouselSheet,
  },
})
export default class PortletsCarouselApp extends Vue {
  @Inject('accountService')
  private accountService: () => AccountService;
  private hasAnyAuthorityValues = {};
  /** Tracks the current item displayed in Carousel */
  public activeCarouselItemIndex = 0;
  public slides: string[] = ['SalesInvoicesCarouselSheet', 'SalesOrdersCarouselSheet'];

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public itemChange(payload) {}

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }
}
