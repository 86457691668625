import { IReportCategory } from '@/shared/model/report-category.model';

export interface IReport {
  id?: number;
  name?: string;
  slug?: string;
  description?: string;
  picture?: string | null;
  type?: string;
  enabled?: boolean;
  expression?: string | null;
  endpointParameters?: string | null;
  authorizationFunction?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  reportCategory?: IReportCategory;
}

export class Report implements IReport {
  constructor(
    public id?: number,
    public name?: string,
    public slug?: string,
    public description?: string,
    public picture?: string | null,
    public type?: string,
    public enabled?: boolean,
    public expression?: string | null,
    public endpointParameters?: string | null,
    public authorizationFunction?: string | null,
    public createdAt?: Date | null,
    public updatedAt?: Date | null,
    public reportCategory?: IReportCategory
  ) {
    this.enabled = this.enabled ?? false;
  }
}
