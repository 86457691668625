import { Component, Prop, Vue } from 'vue-property-decorator';
import SalesDeliveriesDashboard from '@/core/spe/dashboard/dashboards/sales-deliveries-dashboard.vue';
import SalesInvoicesDashboard from '@/core/spe/dashboard/dashboards/sales-invoices-dashboard.component';

@Component({
  components: { SalesInvoicesDashboard, SalesDeliveriesDashboard },
})
export default class SalesDeliveriesCarouselSheet extends Vue {
  @Prop({ required: true, type: Number }) readonly carouselItemIndex;
  @Prop({ required: true, type: Number }) readonly activeCarouselItemIndex;
  public color = 'pink darken-2';
  public slide = 'Expedições';

  get disabled(): boolean {
    return this.carouselItemIndex !== this.activeCarouselItemIndex;
  }
}
