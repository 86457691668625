import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
// BEGIN SPE
import { Authority } from '@/shared/security/authority';
import PortletsCarouselApp from '@/core/spe/dashboard/pages/portlets-carousel-app.vue';
import ReportCategorySlug from '@/core/spe/report-category-slug/report-category-slug.vue';
import FlexmonsterPivotTable from '@/core/spe/pivot-table/flexmonster-pivot-table.vue';
import Base from '@/core/spe/dashboard/dashboards/base.vue';

// END SPE

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [

    {
      path: '/',
      name: 'Home',
      // SPE Begin
      // component: Home
      component: PortletsCarouselApp,
      // meta: { authorities: [Authority.ADMIN, Authority.BI] },
      // SPE End
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    // SPE Begin
    {
      path: '/web-reports/category/:categorySlug',
      name: 'ReportsByCategorySlug',
      component: ReportCategorySlug,
      meta: { authorities: [Authority.ADMIN, Authority.BI] },
    },
    {
      path: '/web-reports/category/:categorySlug/report/:reportSlug',
      name: 'FlexmonsterPivotTable',
      component: FlexmonsterPivotTable,
      meta: { authorities: [Authority.ADMIN, Authority.BI] },
    },
    // SPE End
    ...admin,
    entities,
    ...pages
  ]
});

export default router;
