import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
// SPE begin
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import { GoAppBarComponent as GoAppbar } from '@gv/ammo-grome';
import { GoRightComponent as GoRight } from '@gv/ammo-grome';
import { GoRightDrawerComponent as GoRightDrawer } from '@gv/ammo-grome';
import { GoAlertStackComponent as GoAlertStack } from '@gv/ammo-grome';
import SpeBottomNavigation from '@/core/spe-bottom-navigation/spe-bottom-navigation.vue';
import SpeLeftDrawer from '@/core/spe-left-drawer/spe-left-drawer.vue';

// SPE end

import '@/shared/config/dayjs';
import { EventBus, EventBusType } from '@gv/ammo-astra';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar, // SPE
    'jhi-footer': JhiFooter, // SPE

    // SPE begin
    'go-app-bar': GoAppbar,
    'spe-left-drawer': SpeLeftDrawer,
    // 'jhi-navbar-extended': JhiNavbarExtended,
    'go-right': GoRight,
    'go-right-drawer': GoRightDrawer,
    'go-alert-stack': GoAlertStack,
    'spe-bottom-navigation': SpeBottomNavigation,
    // SPE end
  },
})
export default class App extends Vue {
  // SPE Begin
  private drawer = false;
  private drawerRight = false;
  private right = false;
  private left = false;

  created() {
    this.register();
  }

  /**
   * Listen for 'notify' events
   * @private
   */
  private register(): void {
    const registry = EventBus.getInstance().register(EventBusType.ToggleDrawer, (drawer: string) => {
      if (drawer === 'drawerRight') {
        if (this.drawerRight) {
          this.drawerRight = !this.drawerRight;
        }
      }
    });
  }
  // SPE End
}
