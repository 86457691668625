import { render, staticRenderFns } from "./sales-invoices-carousel-sheet.vue?vue&type=template&id=3575491a&scoped=true&"
import script from "./sales-invoices-carousel-sheet.component.ts?vue&type=script&lang=ts&"
export * from "./sales-invoices-carousel-sheet.component.ts?vue&type=script&lang=ts&"
import style0 from "./sales-invoices-carousel-sheet.vue?vue&type=style&index=0&id=3575491a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3575491a",
  null
  
)

export default component.exports