import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import { IReport } from '@/shared/model/report.model';
import ReportCategorySlugService from '@/core/spe/report-category-slug/report-category-slug.service';
import AlertService from '@/shared/alert/alert.service';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  components: {},
})
export default class ReportCategorySlug extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Provide('reportCategorySlugService')
  private reportCategorySlugService = () => new ReportCategorySlugService();

  public reports: IReport[] = [];
  public isFetching = false;

  beforeRouteUpdate(to, from, next) {
    this.retrieveReports(to.params.categorySlug);
    next();
  }
  mounted() {
    this.retrieveReports(this.$route.params.categorySlug);
  }
  retrieveReports(slug: string) {
    this.$set(this, 'reports', []);

    this.isFetching = true;
    this.reportCategorySlugService()
      .retrieve(`reports/category-slug/${slug}`)
      .then(res => {
        this.$set(this, 'reports', res);
        this.isFetching = false;
      })
      .catch(err => {
        this.isFetching = false;
        this.alertService().showHttpError(this, err.response);
      });
  }
  getReportUrl(slug: string) {
    return `/web-reports/category/${this.$route.params.categorySlug}/report/${slug}`;
  }
}
