import { Component, Inject, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import { AlertService } from '@gv/ammo-grome';
import { GardianService, QueryService } from '@gv/ammo-astra';
import BusinessAnalyticsService from '@/core/spe/dashboard/business-analytics.service';
import VueApexCharts from 'vue-apexcharts';
import { colors } from 'vuetify/lib';
@Component({
  components: { VueApexCharts },
})
export default class SalesOrdersDashboard extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('businessAnalyticsService')
  private businessAnalyticsService: () => BusinessAnalyticsService;

  @Prop({ required: true, type: Boolean }) readonly disabled;
  @Prop({ type: Object }) readonly queryParameters: { [k: string]: any } | null;

  public salesOrderTotalsByReps: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };

  public salesOrderTotalsByCreateUser: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };

  public salesOrderTotalsByWeek: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };

  public salesOrderTotalsByGroup: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };

  mounted() {
    this.fetch();
  }

  @Watch('queryParameters')
  function(newQueryParameters) {
    this.fetch();
  }

  getApexChart(url: string, params: { [k: string]: any }, chart) {
    chart.loading = true;

    this.businessAnalyticsService()
      .retrieve(url, params)
      .then(res => {
        chart.options = res.options;
        // Pie / Donut chart type use doubleSeries
        chart.series = res.series ?? res.doubleSeries;
        chart.options.colors = [
          '#008FFB',
          '#00E396',
          '#f37422',
          '#ffdd3a',
          '#FF4560',
          '#FF33FF',
          '#FF5511',
          '#60BD68',
          '#da3a81',
          '#775DD0',
          '#B276B2',
          '#2196f3',
          '#ff9800',
          '#F17CB0',
          '#F15854',
          '#B2912F',
          '#FFB399',
          '#5DA5DA',
          '#DECF3F',
        ];

        if (chart.options.chart.id === 'sales-orders-lines-totals-by-week') {
          chart.options.xaxis.max = chart.options.xaxis.categories.length;
          chart.options.colors = '#f37422';
        }

        // if (chart.options.chart.id === 'sales-orders-lines-totals-by-group') {
        // }

        this.evalFormatter(chart);
      })
      .catch(err => {
        chart.series = [];
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => (chart.loading = false));
  }

  /**
   * Helper to simplify setting formatter in apex charts.
   * @param chart
   */
  evalFormatter(chart) {
    if (chart.options.yaxis && chart.options.yaxis.labels.formatter) {
      chart.options.yaxis.labels.formatter = eval(chart.options.yaxis.labels.formatter);
    }

    if (chart.options.dataLabels && chart.options.dataLabels.formatter) {
      chart.options.dataLabels.formatter = eval(chart.options.dataLabels.formatter);
    }
  }
  fetch() {
    this.getApexChart('v1/business-analytics/sales/order/lines/totals/by-reps', this.queryParameters, this.salesOrderTotalsByReps);
    this.getApexChart(
      'v1/business-analytics/sales/order/lines/totals/by-create-user',
      this.queryParameters,
      this.salesOrderTotalsByCreateUser
    );
    this.getApexChart('v1/business-analytics/sales/order/lines/totals/by-week', this.queryParameters, this.salesOrderTotalsByWeek);
    this.getApexChart('v1/business-analytics/sales/order/lines/totals/by-group', this.queryParameters, this.salesOrderTotalsByGroup);
  }
}
