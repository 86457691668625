/**
 * Synchronize hydrateParameters(parameters) in flexmonster component.
 */
export const ParametersEnum = {
  dateRange: {
    keys: ['startDate', 'endDate'],
    title: 'rafaleApp.spe.parameters.dateRange.title',
    enabled: false,
    components: [
      {
        is: 'parameter-date-picker-range',
        props: {
          label: 'Data início',
          dark: true,
        },
        dataSource: null,
        value: '',
        qs: 'start_date',
      },
      {
        is: 'parameter-date-picker-range',
        props: {
          label: 'Data fim',
          dark: true,
        },
        dataSource: null,
        value: '',
        qs: 'end_date',
      },
    ],
  },
  companies: {
    keys: ['companies'],
    value: 'cpy0',
    text: 'cpynam0',
    title: 'rafaleApp.spe.parameters.companies.title',
    enabled: false,
    components: [
      {
        is: 'parameter-select',
        props: {
          multiple: true,
          dense: true,
          dark: false,
        },
        dataSource: [],
        value: '',
        qs: 'companies',
      },
    ],
  },
  sites: {
    keys: ['sites'],
    value: 'fcy0',
    text: 'fcynam0',
    title: 'rafaleApp.spe.parameters.sites.title',
    enabled: false,
    components: [
      {
        is: 'parameter-select',
        props: {
          multiple: true,
          dense: true,
          dark: false,
        },
        dataSource: [],
        value: '',
        qs: 'sites',
      },
    ],
  },
  salesReps: {
    keys: ['salesReps'],
    value: 'repnum0',
    text: 'repnam0',
    title: 'rafaleApp.spe.parameters.salesReps.title',
    enabled: false,
    components: [
      {
        is: 'parameter-select',
        props: {
          multiple: true,
          dense: true,
          dark: false,
        },
        dataSource: [],
        value: '',
        qs: 'sales_reps',
      },
    ],
  },
};
