import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SalesQuotesCarouselSheet extends Vue {
  //*********** START PROPS ***********
  @Prop({ required: true, type: Number }) readonly carouselItemIndex;
  @Prop({ required: true, type: Number }) readonly activeCarouselItemIndex;
  //*********** END PROPS ***********

  //*********** START DATA ***********
  public color = 'indigo';
  public slide = 'Orçamentos';
  //*********** END DATA ***********
}
