import { render, staticRenderFns } from "./portlets-carousel-app.vue?vue&type=template&id=2ba01801&scoped=true&"
import script from "./portlets-carousel-app.component.ts?vue&type=script&lang=ts&"
export * from "./portlets-carousel-app.component.ts?vue&type=script&lang=ts&"
import style0 from "./portlets-carousel-app.vue?vue&type=style&index=0&id=2ba01801&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba01801",
  null
  
)

export default component.exports