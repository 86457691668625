export default class Formatter {
  constructor(public locale, public theme) {
    this.locale = locale;
    this.theme = theme;
  }

  public toLocaleInteger(value) {
    return Number(value).toLocaleString(this.locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  public toLocalePercentage(value, fractionDigits) {
    return (
      Number(value).toLocaleString(this.locale, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }) + '%'
    );
  }

  public getColor(value) {
    let success = 'green';
    let warning = 'red';

    if ('isDark' in this.theme && 'isDark' in this.theme) {
      if ('dark' in this.theme.themes && this.theme.isDark === true) {
        if ('success' in this.theme.themes.dark) success = this.theme.themes.dark.success;
        if ('error' in this.theme.themes.dark) warning = this.theme.themes.dark.error;
      } else if ('light' in this.theme.themes && this.theme.isDark === false) {
        if ('success' in this.theme.themes.light) success = this.theme.themes.light.success;
        if ('error' in this.theme.themes.light) warning = this.theme.themes.light.error;
      }
    }

    if (value > 0) return success;
    else if (value < 0) return warning;
    else return 'transparent';
  }

  getArrowIcon(value) {
    if (value > 0) return 'mdi-arrow-up';
    else if (value < 0) return 'mdi-arrow-down';
    else return '';
  }
}
