import { Component, Inject, Provide, Vue } from 'vue-property-decorator';
import { AlertService } from '@gv/ammo-grome';
import { GardianService, QueryService } from '@gv/ammo-astra';
import BusinessAnalyticsService from '@/core/spe/dashboard/business-analytics.service';
import DashboardApp from '@/core/spe/dashboard/dashboards/dashboard-app.vue';

@Component({
  components: { DashboardApp },
})
export default class SalesDeliveriesDashboard extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('businessAnalyticsService')
  private businessAnalyticsService: () => BusinessAnalyticsService;
}
