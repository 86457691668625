import axios from 'axios';

import { IReport } from '@/shared/model/report.model';

const baseApiUrl = '/services/panther/api/v1';

export default class ReportCategorySlugService {
  public retrieve(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${url}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
