import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import Formatter from '@/move-into-ammo/fromatter';
import { AlertService } from '@gv/ammo-grome';
import { AlertType, FunctionAuthorization, GardianService, IFunctionAuthorization, QueryService } from '@gv/ammo-astra';
import BusinessAnalyticsService from '@/core/spe/dashboard/business-analytics.service';
import ParameterGroup from '@/core/spe/parameter/parameter-group.vue';
import ParameterService from '@/shared/config/spe/parameter.service';
import SalesOrdersDashboard from '@/core/spe/dashboard/dashboards/sales-orders-dashboard.vue';
import { IParameters, Parameters } from '@/shared/config/spe/parameters';
import SalesInvoicesDashboard from '@/core/spe/dashboard/dashboards/sales-invoices-dashboard.component';
import { replace } from 'sinon';

const ERP_FUNCTION = 'CONSCSP';
@Component({
  components: {
    SalesInvoicesDashboard,
    SalesOrdersDashboard,
    ParameterGroup,
  },
})
export default class SalesOrdersCarouselSheet extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('parameterService')
  private parameterService: () => ParameterService;

  @Inject('businessAnalyticsService')
  private businessAnalyticsService: () => BusinessAnalyticsService;

  @Prop({ required: true, type: Number }) readonly carouselItemIndex;
  @Prop({ required: true, type: Number }) readonly activeCarouselItemIndex;

  public slide: { [k: string]: any } = {
    name: 'Encomendas',
    // color: 'warning',
    color: '#df1e5a',
    data: {
      currencyCode: '',
      netPriceExcludeTax: 0,
      number: 0,
    },
  };
  public formatter = null;
  public parameters: { [k: string]: any } = {};
  public queryParameters: { [k: string]: any } = null;
  public parametersReady = false;
  public authorized = true;
  public isParameterDrawerActive = true;

  // --- Function authorization ---
  public functionAuthorization: IFunctionAuthorization = new FunctionAuthorization(null, false, false, false, null, [], [], []);
  public isFunctionAuthorizationFetching = false;
  public parameter: IParameters = new Parameters(null, null, null, null, null);

  get disabled(): boolean {
    return this.carouselItemIndex !== this.activeCarouselItemIndex;
  }

  created() {
    this.formatter = new Formatter(this.$vuetify.lang.current, this.$vuetify.theme);
    this.retrieveFunctionAuthorization()
      .then(() => {
        const parameters = this.parameterService().fromFunctionAuthorization(this.functionAuthorization);
        this.parameterService().hydrateParameters(this, parameters);
        this.parametersReady = true;
      })
      .catch(err => {
        this.parametersReady = false;
      });
  }
  private fetch() {
    this.getLineTotals();
  }
  @Watch('activeCarouselItemIndex')
  onActiveCarouselItemIndexChange(val: number) {
    if (val === this.carouselItemIndex) {
      this.fetch();
    }
  }
  mounted() {
    this.fetch();
  }

  public beforeDestroy() {
    this.isParameterDrawerActive = false;
  }

  public retrieveFunctionAuthorization(): Promise<boolean> {
    return new Promise(resolve => {
      this.isFunctionAuthorizationFetching = true;

      this.gardianService()
        .functionAuthorization({ function: ERP_FUNCTION })
        .then(param => {
          this.functionAuthorization.accessTypeObject = param.accessTypeObject;
          this.functionAuthorization.authorizationSite = param.authorizationSite;
          this.functionAuthorization.authorizedAccessTypeObject = param.authorizedAccessTypeObject;
          this.functionAuthorization.defaultSite = param.defaultSite;
          this.functionAuthorization.authorizedCompanies.push(...param.authorizedCompanies);
          this.functionAuthorization.authorizedSites.push(...param.authorizedSites);
          this.functionAuthorization.authorizedSalesrep.push(...param.authorizedSalesrep);
          this.isFunctionAuthorizationFetching = false;
          resolve(true);
        })
        .catch(error => {
          this.isFunctionAuthorizationFetching = false;
          const errMessage = error?.response?.data?.message ?? '';
          this.alertService().showAlert(this, AlertType.Error, `${error.message}${errMessage !== '' ? '<br>' + errMessage : ''}`, null, -1);
          resolve(false);
        });
    });
  }

  public replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  getLineTotals() {
    this.businessAnalyticsService()
      .retrieve('v1/business-analytics/sales/order/not-closed-lines/totals', this.queryParameters)
      .then(res => {
        this.slide.data.currencyCode = res['currency_code'];
        this.slide.data.netPriceExcludeTax = this.formatter
          .toLocaleInteger(res['open_orders_net_price_exclude_tax'])
          .replace(/[,\.]/g, ' ');
        this.slide.data.number = this.formatter.toLocaleInteger(res['open_orders_number']).replace(/[,\.]/g, ' ');
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.slide.data.currencyCode = '';
          this.slide.data.netPriceExcludeTax = 0;
          this.slide.data.number = 0;
          // this.alertService().showHttpError(this, err.response);
        }
      });
  }

  /**
   * Handler function for 'refresh-query'
   * @param payload
   */
  refreshQuery(payload) {
    // Update parent object to reflect parameters selections
    this.parameters = payload;
    this.queryParameters = this.parameterService().toQueryParameters(this.parameters);
    // Updates dashboard data
    this.getLineTotals();
  }
}
