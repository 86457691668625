import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import { AlertService } from '@gv/ammo-grome';
import { GardianService, QueryService } from '@gv/ammo-astra';
import BusinessAnalyticsService from '@/core/spe/dashboard/business-analytics.service';

@Component({})
export default class SalesInvoicesDashboard extends Vue {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('queryService')
  private queryService: () => QueryService;

  @Inject('gardianService')
  private gardianService: () => GardianService;

  @Inject('businessAnalyticsService')
  private businessAnalyticsService: () => BusinessAnalyticsService;

  @Prop({ required: true, type: Object }) readonly formatter;
  @Prop({ required: true, type: Boolean }) readonly disabled;
  @Prop({ type: Object }) readonly queryParameters: { [k: string]: any } | null;

  public salesInvoiceLinesLastThreeYearsMonthTotalsChart: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };
  public panel: [0];
  public salesInvoiceLinesYearMonthAndMonthsBeforeHomologous: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };
  public salesRepsSituationChart: { [k: string]: any } = {
    loading: false,
    chartWidth: '100%',
    options: {},
    series: [],
  };
  public tableCustomersRetentionsSearch = '';
  public tableCustomersRetentions: { [k: string]: any } = {
    loading: false,
    headers: [],
    data: [],
  };

  public tit;

  mounted() {
    this.fetch();
  }

  @Watch('queryParameters')
  function(newQueryParameters) {
    this.fetch();
  }

  getApexChart(url: string, params: { [k: string]: any }, chart) {
    chart.loading = true;

    this.businessAnalyticsService()
      .retrieve(url, params)
      .then(res => {
        chart.options = res.options;
        // Pie / Donut chart type use doubleSeries
        chart.series = res.series ?? res.doubleSeries;

        if (chart.options.chart.id === 'sales-orders-lines-totals-by-week') {
          this.tit = chart.options.title.text;
        }

        chart.options.colors = [
          '#008FFB',
          '#f37422',
          '#00E396',
          '#ffdd3a',
          '#FF4560',
          '#FF33FF',
          '#FF5511',
          '#60BD68',
          '#da3a81',
          '#775DD0',
          '#B276B2',
          '#2196f3',
          '#ff9800',
          '#F17CB0',
          '#F15854',
          '#B2912F',
          '#FFB399',
          '#5DA5DA',
          '#DECF3F',
        ];

        this.evalFormatter(chart);
      })
      .catch(err => {
        chart.series = [];
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => (chart.loading = false));
  }

  getTable(url: string, params: { [k: string]: any }, table: { [k: string]: any }) {
    table.loading = true;

    this.businessAnalyticsService()
      .retrieve(url, params)
      .then(res => {
        table.headers = res.headers;
        table.data = res.data;

        if (res.data.dense) {
          table['dense'] = res.dense;
        }
        if (res.data.class) {
          table['class'] = res.class;
        }
      })
      .catch(err => {
        this.alertService().showHttpError(this, err.response);
      })
      .finally(() => (table.loading = false));
  }
  /**
   * Helper to simplify setting formatter in apex charts.
   * @param chart
   */
  evalFormatter(chart) {
    if (chart.options.yaxis && chart.options.yaxis.labels.formatter) {
      chart.options.yaxis.labels.formatter = eval(chart.options.yaxis.labels.formatter);
    }

    if (chart.options.dataLabels && chart.options.dataLabels.formatter) {
      chart.options.dataLabels.formatter = eval(chart.options.dataLabels.formatter);
    }
  }

  fetch() {
    this.getApexChart(
      'v1/business-analytics/sales/invoice/last-three-years-month-totals/chart',
      this.queryParameters,
      this.salesInvoiceLinesLastThreeYearsMonthTotalsChart
    );

    const month = new Date().getMonth() + 1;

    this.getApexChart(
      'v1/business-analytics/sales/invoice/year-month-and-months-before-homologous/chart',
      this.queryParameters ? { ...this.queryParameters, month } : { month },
      this.salesInvoiceLinesYearMonthAndMonthsBeforeHomologous
    );

    this.getApexChart('v1/business-analytics/sales/invoice/sales-reps-situation/chart', this.queryParameters, this.salesRepsSituationChart);

    this.getTable(
      'v1/business-analytics/sales/invoice/customers-retentions',
      this.queryParameters ? { ...this.queryParameters, top_n: 30 } : { top_n: 30 },
      this.tableCustomersRetentions
    );
  }
}
