// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// SPE Begin AxiosSingleton
// import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';  // SPE
import { setupAxiosInterceptors, AxiosSingleton } from '@/shared/config/axios-interceptor';
// SPE End AxiosSingleton

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
// SPE Begin custom service
// import LoginService from './account/login.service';
// import AccountService from './account/account.service';
// import AlertService from './shared/alert/alert.service';
import { LoginService, AccountService, AlertService } from '@gv/ammo-grome';
// SPE End custom services

// SPE Begin remove bootstrap-sass
// import '../content/scss/global.scss';
// import '../content/scss/vendor.scss';
// SPE End remove bootstrap-sass
// SPE Begin TranslationService
// import TranslationService from '@/locale/translation.service';
import { TranslationService } from '@gv/ammo-grome';
// SPE End TranslationService
/* tslint:disable */

// @ts-ignore
window.$ = window.jQuery = require('jquery');

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
// SPE Begin vuetify-import
import vuetify from '../plugins/vuetify';
// SPE End vuetify-import
// SPE Begin portal-vue
import PortalVue from 'portal-vue';
// SPE End portal-vue
// SPE Begin custom services
import { GardianService, QueryService, LocalMenuService, SubprogService } from '@gv/ammo-astra';
import { Provide } from 'vue-property-decorator';
// SPE End custom services
import VueApexCharts from 'vue-apexcharts';
import ParameterService from '@/shared/config/spe/parameter.service';
import BusinessAnalyticsService from '@/core/spe/dashboard/business-analytics.service';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
// SPE Begin use portal-vue
Vue.use(PortalVue);
// SPE End use portal-vue
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

// SPE Begin custom services
const gardianService = new GardianService(AxiosSingleton.getInstance());
const queryService = new QueryService(AxiosSingleton.getInstance());
// SPE End custom services
const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
// const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router); // SPE
const accountService = new AccountService(store, translationService, (<any>Vue).cookie, router, gardianService); // SPE

router.beforeEach(async (to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  // SPE Begin config vuetify
  vuetify,
  // SPE End config vuetify
  provide: {
    loginService: () => loginService,
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    // SPE custom service
    gardianService: () => gardianService,
    localMenuService: () => new LocalMenuService(AxiosSingleton.getInstance()),
    queryService: () => queryService,
    subprogService: () => new SubprogService(AxiosSingleton.getInstance()),
    parameterService: () => new ParameterService(),
    businessAnalyticsService: () => new BusinessAnalyticsService(queryService),
    // alertService: () => new AlertService(),   // SPE disable std service
    alertService: () => new AlertService(store), // SPE custom alert service
    // SPE End custom alert service
    // SPE Begin custom services
    // SPE End custom services
  },
  i18n,
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        window.location.reload();
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    console.log('Server error!');
    return Promise.reject(error);
  }
);
