import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { TranslationService } from '@gv/ammo-grome';

@Component({})
export default class ParameterDatePickerRange extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;

  private languages: any = this.$store.getters.languages;

  private currentLanguage = this.$store.getters.currentLanguage;

  @Prop({ required: true, type: String }) readonly value;

  @Prop({ required: true, type: String }) readonly label;

  @Prop({ required: true, type: Boolean }) readonly dark;

  public modal = false;

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
  }

  public input(payload) {
    this.$emit('input', payload);
  }
}
