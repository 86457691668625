import { ParametersEnum } from './parameters-enum';
import Vue from 'vue';
import { FunctionAuthorization, IFunctionAuthorization } from '@gv/ammo-astra';
import { IParameters, Parameters } from '@/shared/config/spe/parameters';

const parametersEnum = { ...ParametersEnum };

export default class ParameterService {
  public fromFunctionAuthorization(functionAuthorization: FunctionAuthorization): Parameters {
    return new Parameters(
      null,
      null,
      null,
      true,
      functionAuthorization.authorizedCompanies,
      functionAuthorization.authorizedCompanies.map(it => it.cpy0),
      true,
      functionAuthorization.authorizedSites,
      functionAuthorization.authorizedSites.map(it => it.fcy0),
      true,
      functionAuthorization.authorizedSalesrep,
      functionAuthorization.authorizedSalesrep.map(it => it.repnum0),
      0
    );
  }

  /**
   *
   * @param vue
   * @param parameters
   */
  public hydrateParameters(vue: Vue, parameters: IParameters) {
    // Run through parameters keys
    for (const [parameterObjectKey, parameterObjectValue] of Object.entries(parametersEnum)) {
      // Run through value type {single; range}
      for (const [index, key] of Object.entries(parameterObjectValue.keys)) {
        if (!(key in parameters)) {
          continue;
        }
        const name = key.charAt(0).toUpperCase() + key.slice(1);

        switch (parameterObjectKey) {
          case 'companies':
          case 'sites':
          case 'salesReps': {
            const authorizedKey = 'authorized' + name;
            const parameterDataSource = parameters[authorizedKey].map(item => {
              return {
                // @ts-ignore
                value: item[parameterObjectValue.value],
                // @ts-ignore
                text: item[parameterObjectValue.text],
              };
            });
            // filter authorized items
            const parameterValue = parameterDataSource.map(item => item.value).filter(it => parameters[key].includes(it));
            const parameter = {
              dataSource: parameterDataSource,
              value: parameterValue,
              enabled: parameters[`enabled${name}`],
            };
            this.setParameter(vue, parameterObjectKey, parameter);
            break;
          }
          case 'dateRange': {
            const parameter = {
              dataSource: parameters[key],
              value: parameters[key],
              enabled: parameters[`enabledDateRange`],
            };
            if (!(parameter.value === null && parameter.dataSource === null)) {
              this.setParameter(vue, parameterObjectKey, parameter);
              break;
            } else {
              break;
            }
          }
          default: {
            break;
          }
        }
      }
    }
  }
  /**
   * Sets a parameter in The target Vue object. ensure that Vue can detect property addition or deletion
   * @param vue The target Vue object.
   * @param parameterObjectKey
   * @param parameter
   */
  setParameter(vue, parameterObjectKey, parameter) {
    // Add parameter to the internal  parameters object
    if (!(parameterObjectKey in vue.parameters)) {
      vue.$set(vue.parameters, parameterObjectKey, []);
    }
    vue.$set(vue.parameters, parameterObjectKey, [...vue.parameters[parameterObjectKey], parameter]);
  }

  /**
   * @param parameters
   * @returns {{}}
   */
  public toQueryParameters(parameters): { [k: string]: any } {
    const obj = {};

    // Run through parameters keys and update the value key
    for (const [parameterObjectKey, parameterObjectValue] of Object.entries(parameters)) {
      // Run through value type {single; range}
      const parameterConfiguration = parametersEnum[parameterObjectKey].components;

      for (const [key, value] of Object.entries(parameterConfiguration)) {
        if (parameters[parameterObjectKey][key].enabled) {
          // @ts-ignore
          obj[value.qs] = parameters[parameterObjectKey][key].value;
        }
      }
    }
    return obj;
  }
}
