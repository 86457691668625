import axios from 'axios';

import { IConfig } from '@/shared/model/config.model';

const baseApiUrl = 'services/panther/api/v1/configs';

export default class ConfigExtendedService {
  public findBySlug(slug: string): Promise<IConfig> {
    return new Promise<IConfig>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/slug/${slug}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
