import axios from 'axios';

import { IReport } from '@/shared/model/report.model';

const baseApiUrl = 'services/panther/api/v1/reports';

export default class ReportExtendedService {
  public findBySlug(slug: string): Promise<IReport> {
    return new Promise<IReport>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}?slug=${slug}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
